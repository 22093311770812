<template>
  <div class="section-upgrade-position">
    <div class="container">
      <div class="box-white">
        <div class="row h-100">
          <div class="col-12 col-md-7 col-xl-6 mb-20 my-sm-auto">
            <div class="display-1 text-primary-1 font-noto font-700 mb-20">ยกระดับธุรกิจของลูกค้า</div>
            <div class="display-4 font-noto font-700 mb-20">เราพร้อมร่วมพัฒนาแผนธุรกิจ<br>ร่วมกับทีมของคุณ</div>
            <p class="mb-0 font-noto font-400">
              ผู้ให้บริการด้านการเป็นที่ปรึกษาให้แก่องค์กรธุรกิจ <span class="font-noto font-700">ปรับปรุงกระบวนการบริหารจัดการ และวางระบบที่มีประสิทธิภาพ</span> เพื่อให้ลูกค้าได้รับผลตอบแทนจากการลงทุนเป็นมูลค่านับไม่ถ้วน โดยทีมที่ปรึกษาผู้เชี่ยวชาญ และมีประสบการณ์ในด้านต่าง ๆ 
              มาอย่างยาวนาน ทั้งการบริหาร การตลาด การเงิน และการบัญชี 
            </p>
          </div>
          <div class="col-12 col-md-5 col-xl-6 my-auto">
            <img width="100%" src="@/assets/images/consult/section-intro.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.section-upgrade-position {
  margin-top: -310px;
}
.box-white {
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 10px;
  padding: 90px;
}

@media (max-width: 1024px) {
  .box-white {
    padding: 65px;
  }
}

@media (max-width: 768px) {
  .section-upgrade-position {
    margin-top: -200px;
  }
  .box-white {
    padding: 30px;
  }
  .display-1 {
    font-size: 28px;
  }
}

@media (max-width: 492px) {
  .section-upgrade-position {
    margin-top: -430px;
  }
  .box-white {
    text-align: center;
    padding: 20px;
  }
}



</style>