<template>
  <div class="pb-80">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="display-footer">
            <div class="left-footer">
              <img src="../../assets/images/logo/logo.svg" alt="logo_finnx">
            </div>
            <div>
              <div class="menu-footer">
                <div class="d-none d-md-block">
                  <div class="menu-item active"><a class="a-menu-item active" href="#home">Home</a></div>
                  <div class="menu-item"><a class="a-menu-item" href="#about">About us</a></div>
                  <div class="menu-item"><a class="a-menu-item" href="#visionandmission">Vision & Mission</a></div>
                  <div class="menu-item"><a class="a-menu-item" href="#ourservices">Services</a></div>
                  <div class="menu-item"><a class="a-menu-item" href="#news">News</a></div>
                  <div class="menu-item"><a class="a-menu-item" target="_blank" href="https://bit.ly/hr_finnx">Careers</a></div>
                  <div class="menu-item"><a class="a-menu-item" href="#contact">Contact</a></div>
                </div>
                <div class="d-block d-md-none">
                  <div class="row justify-content-center mb-20">
                    <div class="col-3 text-start"><div class="menu-item active"><a class="a-menu-item active" href="#home">Home</a></div></div>
                    <div class="col-3 text-end"><div class="menu-item"><a class="a-menu-item" href="#about">About us</a></div></div>
                  </div>
                  <div class="row justify-content-center mb-20">
                    <div class="col-6 text-start"><div class="menu-item"><a class="a-menu-item" href="#visionandmission">Vision & Mission</a></div></div>
                  </div>
                  <div class="row justify-content-center mb-20">
                    <div class="col-3 text-start"><div class="menu-item"><a class="a-menu-item" href="#ourservices">Services</a></div></div>
                    <div class="col-3 text-end"><div class="menu-item"><a class="a-menu-item" href="#news">News</a></div></div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-3 text-start"><div class="menu-item"><a class="a-menu-item" target="_blank" href="https://bit.ly/hr_finnx">Careers</a></div></div>
                    <div class="col-3 text-end"><div class="menu-item"><a class="a-menu-item" href="#contact">Contact</a></div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.display-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.active {
  color: #EF3E17 !important;
}
.a-menu-item {
  text-decoration: none;
  color: #FFFFFF;

  &:hover {
    color: #EF3E17;
  }
}
.menu-item {
  display: inline-block;
  margin-right: 30px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}

@media (max-width: 492px) {
  .display-footer {
    display: block;
    text-align: center;
  }
  .left-footer {
    margin-bottom: 20px;
  }
}
</style>