<template>
  <div class="setup-section">
    <div class="container">
      <div class="text-center mb-20">
        <div class="badge font-public mb-10">PREMIUM SERVICE</div>
        <div class="text-header font-public">Data Transformation</div>
        <p class="mb-0 display-p font-noto">บริการสุดฮิตที่หลายองค์กรใช้บริการมากที่สุด  บริการให้คำปรึกษา และวางระบบงานด้าน Data <br> เพื่อปรับใช้ในองค์กรให้เกิดประสิทธิภาพ และผลลัพธ์สูงสุด</p>
      </div>

      <!-- desktop -->
      <div class="d-none d-lg-block">
        <div class="row justify-content-center">
          <div class="col-12 col-md-4 mb-20">
            <div class="card-data-1" data-aos="fade-up" data-aos-delay="100">
              <div class="display-text-card-data">
                <h3 class="display-3 font-noto font-700">ลดความผิดพลาดในการตัดสินใจ เปลี่ยนจากการคาดเดา เป็นความจริง</h3>
                <p class="display-sm font-noto mb-0">คุณจะได้ลงลึกถึงกระบวนการตัดสินใจ บนเครื่องมือที่ช่วยคุณวิเคราะห์ Data จากทุกมิติ เพื่อลดความเสี่ยงในการตัดสินใจผิดพลาด เพราะค่าเสียโอกาสของธุรกิจเป็นสิ่งที่ประเมินมูลค่าไม่ได้</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 mb-20">
            <div class="card-data-2" data-aos="fade-up" data-aos-delay="300">
              <div class="display-text-card-data">
                <h3 class="display-3 font-noto font-700">8 ใน 10 ผลิตภัณฑ์ใหม่ๆ จะขายไม่ออก</h3>
                <p class="display-sm font-noto mb-0">อยากตัดสินใจได้อย่างเฉียบขาด ต้องวิเคราะห์อย่างไร คุณจะได้เรียนรู้ เทคนิคขั้นสูงในการวิเคราะห์ Data เพื่อนำมาออกแบบ สินค้า / บริการ ใหม่ๆ เพื่อส่งเข้าสู่ตลาด</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 mb-20">
            <div class="card-data-3" data-aos="fade-up" data-aos-delay="500">
              <div class="display-text-card-data">
                <h3 class="display-3 font-noto font-700">รู้เขา (คู่แข่ง) รู้เรา (ความสามารถในการแข่ง) รบอย่างไร ชนะ 100 ครั้ง</h3>
                <p class="display-sm font-noto mb-0">ใช้ Business Analytics วิเคราะห์สถานการณ์ตลาด ด้วยเทคนิคการวิเคราะห์ทั้ง 3 ประเภท ซึ่งเป็นหัวใจสำคัญของการ "แก้เกม" ที่ผู้บริหารจำเป็นต้องรู้</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card-data-4" data-aos="fade-up" data-aos-delay="100">
              <div class="display-text-card-data">
                <h3 class="display-3 font-noto font-700">เป็นคนแรก <br>ที่จับ Trend เสมอ</h3>
                <p class="display-sm font-noto mb-0">ถ้าความต้องการของลูกค้าเปลี่ยนไป เราต้องรู้เป็นคนแรก!! คุณจะได้เรียนรู้วิธีที่ทำให้ธุรกิจของคุณ เป็นคนแรกที่จับ Trend ได้ทันเสมอ เพราะความสนใจ และความต้องการของลูกค้ายุคนี้ เปลี่ยนไปเร็วกว่าเดิม</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card-data-5" data-aos="fade-up" data-aos-delay="300">
              <div class="display-text-card-data">
                <h3 class="display-3 font-noto font-700">สร้าง Executive Dashboard</h3>
                <p class="display-sm font-noto mb-0">เพื่อให้คุณได้มีระบบอัตโนมัติคอยติดตามว่าธุรกิจคุณกำลังเดินถูกทางตามเป้าที่ตั้งไว้หรือไม่ ต้องระวังอะไรเป็นพิเศษ พร้อมแจ้งเตือนคุณทันทีถ้ามีบางอย่างผิดปกติในธุรกิจ</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ipad mobile -->
      <div class="d-block d-lg-none">
        <div class="box-carousel" v-on:scroll.passive="handleScroll">
          <div class="card-data-1" data-aos="fade-up" data-aos-delay="100">
            <div class="display-text-card-data">
              <h3 class="display-3 font-noto font-700">ลดความผิดพลาดในการตัดสินใจ เปลี่ยนจากการคาดเดา เป็นความจริง</h3>
              <p class="display-sm font-noto mb-0">คุณจะได้ลงลึกถึงกระบวนการตัดสินใจ บนเครื่องมือที่ช่วยคุณวิเคราะห์ Data จากทุกมิติ เพื่อลดความเสี่ยงในการตัดสินใจผิดพลาด เพราะค่าเสียโอกาสของธุรกิจเป็นสิ่งที่ประเมินมูลค่าไม่ได้</p>
            </div>
          </div>
          <div class="card-data-2" data-aos="fade-up" data-aos-delay="300">
            <div class="display-text-card-data">
              <h3 class="display-3 font-noto font-700">8 ใน 10 ผลิตภัณฑ์ใหม่ๆ จะขายไม่ออก</h3>
              <p class="display-sm font-noto mb-0">อยากตัดสินใจได้อย่างเฉียบขาด ต้องวิเคราะห์อย่างไร คุณจะได้เรียนรู้ เทคนิคขั้นสูงในการวิเคราะห์ Data เพื่อนำมาออกแบบ สินค้า / บริการ ใหม่ๆ เพื่อส่งเข้าสู่ตลาด</p>
            </div>
          </div>
          <div class="card-data-3" data-aos="fade-up" data-aos-delay="500">
            <div class="display-text-card-data">
              <h3 class="display-3 font-noto font-700">รู้เขา (คู่แข่ง) รู้เรา (ความสามารถในการแข่ง) รบอย่างไร ชนะ 100 ครั้ง</h3>
              <p class="display-sm font-noto mb-0">ใช้ Business Analytics วิเคราะห์สถานการณ์ตลาด ด้วยเทคนิคการวิเคราะห์ทั้ง 3 ประเภท ซึ่งเป็นหัวใจสำคัญของการ "แก้เกม" ที่ผู้บริหารจำเป็นต้องรู้</p>
            </div>
          </div>
          <div class="card-data-4" data-aos="fade-up" data-aos-delay="100">
            <div class="display-text-card-data">
              <h3 class="display-3 font-noto font-700">เป็นคนแรก <br>ที่จับ Trend เสมอ</h3>
              <p class="display-sm font-noto mb-0">ถ้าความต้องการของลูกค้าเปลี่ยนไป เราต้องรู้เป็นคนแรก!! คุณจะได้เรียนรู้วิธีที่ทำให้ธุรกิจของคุณ เป็นคนแรกที่จับ Trend ได้ทันเสมอ เพราะความสนใจ และความต้องการของลูกค้ายุคนี้ เปลี่ยนไปเร็วกว่าเดิม</p>
            </div>
          </div>
          <div class="card-data-5" data-aos="fade-up" data-aos-delay="300">
            <div class="display-text-card-data">
              <h3 class="display-3 font-noto font-700">สร้าง Executive Dashboard</h3>
              <p class="display-sm font-noto mb-0">เพื่อให้คุณได้มีระบบอัตโนมัติคอยติดตามว่าธุรกิจคุณกำลังเดินถูกทางตามเป้าที่ตั้งไว้หรือไม่ ต้องระวังอะไรเป็นพิเศษ พร้อมแจ้งเตือนคุณทันทีถ้ามีบางอย่างผิดปกติในธุรกิจ</p>
            </div>
          </div>
        </div>

        <!-- scroll -->
        <div class="gray-bar d-block d-lg-none mb-20">
          <div class="active-bar" :style="{'left': scrollPosition+'px'}"></div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        scrollPosition: 0,
      }
    },
    methods: {
      handleScroll(e) {
        const current = e.target.scrollLeft
        const clientWidth = e.target.clientWidth
        const width = e.target.scrollWidth - clientWidth

        const scrollWidth = (clientWidth * 0.9) - 100
        this.scrollPosition = parseInt((current * scrollWidth) / width)
      },
    },
  }
</script>

<style lang="scss" scoped>
.display-3, .display-sm {
  color: #FFF;
}
.setup-section {
  // background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(239,62,23,1) 50%, rgba(255,255,255,1) 100%);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,212,203,1) 20%, rgba(239,62,23,1) 50%, rgba(255,212,203,1) 80%, rgba(255,255,255,1) 100%);
  padding-top: 250px;
  padding-bottom: 250px;

  margin-top: -240px;
}
.badge {
  font-size: 18px;
  background: #EF3E17;
  border-radius: 10px;
  color: #FFF;
  padding: 5px 15px;
}
.text-header {
  font-size: 60px;
  background: linear-gradient(180deg, #EF3E17 0%, #2B2B2B 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  // text-fill-color: transparent;
}
.card-data-1, .card-data-2, .card-data-3, .card-data-4, .card-data-5 {
  height: 460px;
  background-size:cover;
  border-radius: 10px;
  position: relative;
  padding: 15px;
  color: #FFF;
}
.card-data-1 {
  background: linear-gradient(360deg, #171717 10.42%, rgba(23, 23, 23, 0) 100%), url('@/assets/images/consult/section-data-predict.png');
}
.card-data-2 {
  background: linear-gradient(360deg, #171717 10.42%, rgba(23, 23, 23, 0) 100%), url('@/assets/images/consult/section-data-analytic.png');
}
.card-data-3 {
  background: linear-gradient(360deg, #171717 10.42%, rgba(23, 23, 23, 0) 100%), url('@/assets/images/consult/section-data-compare.png');
}
.card-data-4 {
  background: linear-gradient(360deg, #171717 10.42%, rgba(23, 23, 23, 0) 100%), url('@/assets/images/consult/section-data-trend.png');
}
.card-data-5 {
  background: linear-gradient(360deg, #171717 10.42%, rgba(23, 23, 23, 0) 100%), url('@/assets/images/consult/section-data-dashboard.png');
}
.display-text-card-data {
  position: absolute;
  bottom: 40px;
}

@media (max-width: 768px) {
  .text-header {
    font-size: 40px;
  }
  .display-text-card-data {
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }

  // box carousel
  .box-carousel{
    white-space: nowrap;
    overflow-x: auto;

    // margin: auto -10px;
    // padding: 5px 10px;
  }
  .card-data-1, .card-data-2, .card-data-3, .card-data-4, .card-data-5 {
    display: inline-block;
    width: 350px;
    margin-right: 15px;
    white-space: normal;
    padding: 25px 20px 20px 20px;
    min-height: 350px;
    vertical-align: top;

    &:last-child {
      margin-right: 0px;
    }
  }
  .gray-bar {
    position: relative;
    background: #E8E8E8;
    border-radius: 100px;
    height: 7px;
    width: 90%;
    margin: 30px auto 0px;
  }
  .active-bar {
    width: 100px;
    height: 7px;
    background: #727272;
    border-radius: 100px;
    position: absolute;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: 492px) {
  .text-header {
    line-height: 100%;
    margin-bottom: 10px;
  }
  .card-data-1, .card-data-2, .card-data-3, .card-data-4, .card-data-5 {
    width: 320px;
  }
}
</style>