<template>
  <div>
    <div class="container">
      <div class="col-12">

        <div class="header-news mb-40">
          <div>
            {{ date }}
          </div>
          <div>
            <h3 class="special-3 text-primary-1">NEWS TODAY</h3>
          </div>
          <div>
            <a class="special-4 text-primary-1 underline-none" target="_blank" href="https://www.linkedin.com/company/finn-x-co-ltd/posts/?feedView=all"><h4>SEE MORE</h4></a>
          </div>
        </div>
        
        <!-- news desktop -->
        <div class="d-none d-lg-block">
          <div class="row justify-content-center">
            <!-- news 1 -->
            <div class="col-12 col-lg-3 border-bottom-news">
              <h4 class="sepecial-4 text-primary-1 mb-10 font-noto display-bold">Bring Agile to the Whole Organization</h4>
              <p class="display-p font-noto mb-20">คุณพงศธร ธนบดีภัทร CEO และผู้ร่วมก่อตั้งบริษัท Finn X ได้ขึ้นร่วมเสวนาในหัวข้อ “สร้างทางรอดในการทำงานยุคใหม่กับการทำงานแบบ Agile” ที่งาน Thailand HR TECHใครที่ไม่ได้ไปร่วมงานก็สามารถไปฟังย้อนหลังได้ที่ <a href="https://lnkd.in/g2AWkZ2D" target="_blank">https://lnkd.in/g2AWkZ2D</a> นาทีที่ 3:17:37 เป็นต้นไป <a href="https://www.linkedin.com/feed/update/urn:li:activity:7024668069713117184/" target="_blank" class="font-noto">อ่านต่อ</a></p>
              <img src="../../assets/images/news/news-1.jpg" class="w-100" alt="news-1">
            </div>

            <!-- news 2 -->
            <div class="col-12 col-lg-6">
              <img src="../../assets/images/news/news-ceo-talk.jpg" class="w-100 mb-20" alt="news-2">
              <h4 class="sepecial-4 text-primary-1 mb-10 font-noto display-bold">CEO TALK : “ต้นไม้ใหญ่ ต้นกล้า หรือเมล็ดพันธ์ุ”</h4>
              <p class="display-p font-noto mb-10">คุณนพ พงศธร CEO เข้าร่วมเป็นวิทยากรในงานสัมมนา CEO TALK by SPU ในหัวข้อ “ORGANIZATIONAL TRANSFORMATION FOR SUSTAINABILITY การเปลี่ยนผ่านองค์กรเพื่อความยั่งยืน” ร่วมแชร์และพูดคุยกับนักศึกษาปริญญาโท และปริญญาเอก ในการบริหารบุคลากรแต่ละประเภท</p>
            </div>

            <!-- news 3 -->
            <div class="col-12 col-lg-3 border-bottom-news">
              <img src="../../assets/images/news/news-data-excutive.jpg" class="w-100 mb-20" alt="news-3">
              <h4 class="sepecial-4 text-primary-1 mb-10 font-noto display-bold">คอร์สสด "Data for Executive"</h4>
              <p class="display-p font-noto mb-20">หลักสูตรที่ได้รับเสียงตอบรับอย่างท่วมท้นจากผู้บริหารระดับสูงหลากหลายองค์กร ด้วยการรวบรวมหลักสูตรจากมหาวิทยาลัยชั้นนำระดับโลกและการออกแบบการสอนอย่างเข้มข้น เพื่อลับคมทักษะการบริหารธุรกิจ และพัฒนาธุรกิจให้เติบโตอย่างก้าวกระโดด แบบ Step-By-Step</p>
            </div>
          </div>
        </div>

        <!-- news mobile -->
        <div class="d-block d-lg-none">
          <div class="row">
            <!-- news 1 -->
            <div class="col-12 col-md-4 mb-30">
              <div class="box-news-mobile border-bottom">
                <img src="../../assets/images/news/news-1.jpg" class="w-100 mb-20" alt="news-1">
                <h4 class="sepecial-4 text-primary-1 mb-10 font-noto display-bold">Bring Agile to the Whole Organization</h4>
                <p class="display-p font-noto mb-20">คุณพงศธร ธนบดีภัทร CEO และผู้ร่วมก่อตั้งบริษัท Finn X ได้ขึ้นร่วมเสวนาในหัวข้อ “สร้างทางรอดในการทำงานยุคใหม่กับการทำงานแบบ Agile” ที่งาน Thailand HR TECHใครที่ไม่ได้ไปร่วมงานก็สามารถไปฟังย้อนหลังได้ที่ <a href="https://lnkd.in/g2AWkZ2D" target="_blank">https://lnkd.in/g2AWkZ2D</a> นาทีที่ 3:17:37 เป็นต้นไป <a href="https://www.linkedin.com/feed/update/urn:li:activity:7024668069713117184/" target="_blank" class="font-noto">อ่านต่อ</a></p>
              </div>
            </div>

            <!-- news 2 -->
            <div class="col-12 col-md-4 mb-30">
              <div class="box-news-mobile border-bottom">
                <img src="../../assets/images/news/news-ceo-talk.jpg" class="w-100 mb-20" alt="news-2">
                <h4 class="sepecial-4 text-primary-1 mb-10 font-noto display-bold">CEO TALK : “ต้นไม้ใหญ่ ต้นกล้า หรือเมล็ดพันธ์ุ”</h4>
                <p class="display-p font-noto mb-10">คุณนพ พงศธร CEO เข้าร่วมเป็นวิทยากรในงานสัมมนา CEO TALK by SPU ในหัวข้อ “ORGANIZATIONAL TRANSFORMATION FOR SUSTAINABILITY การเปลี่ยนผ่านองค์กรเพื่อความยั่งยืน” ร่วมแชร์และพูดคุยกับนักศึกษาปริญญาโท และปริญญาเอก ในการบริหารบุคลากรแต่ละประเภท</p>
              </div>
            </div>

            <!-- news 3 -->
            <div class="col-12 col-md-4">
              <div class="box-news-mobile">
                <img src="../../assets/images/news/news-data-excutive.jpg" class="w-100 mb-20" alt="news-3">
                <h4 class="sepecial-4 text-primary-1 mb-10 font-noto display-bold">คอร์สสด "Data for Executive"</h4>
                <p class="display-p font-noto mb-20">หลักสูตรที่ได้รับเสียงตอบรับอย่างท่วมท้นจากผู้บริหารระดับสูงหลากหลายองค์กร ด้วยการรวบรวมหลักสูตรจากมหาวิทยาลัยชั้นนำระดับโลกและการออกแบบการสอนอย่างเข้มข้น เพื่อลับคมทักษะการบริหารธุรกิจ และพัฒนาธุรกิจให้เติบโตอย่างก้าวกระโดด แบบ Step-By-Step</p>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      date() {
        const date = new Date();
        
        const dayList = {
          '0': 'SUN',
          '1': 'MON',
          '2': 'TUE',
          '3': 'WED',
          '4': 'THU',
          '5': 'FRI',
          '6': 'SAT'
        }

        const monthList = {
          '0': 'JAN',
          '1': 'FEB',
          '2': 'MAR',
          '3': 'APR',
          '4': 'MAY',
          '5': 'JUN',
          '6': 'JUL',
          '7': 'AUG',
          '8': 'SEP',
          '9': 'OCT',
          '10': 'NOV',
          '11': 'DEC'
        }
        
        return dayList[date.getDay()] + ' ' + date.getDate() + ' ' + monthList[date.getMonth()] + ' ' + date.getFullYear()
      }, 
    },
  }
</script>

<style lang="scss" scoped>
.font-noto {
  color: #FFF;
}
.header-news {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  border-bottom: 2px solid #EF3E17;
  padding-bottom: 10px;
}
.border-bottom-news {
  border-bottom: 2px solid #EF3E17;
  padding-bottom: 25px;
}

@media (max-width: 768px) {
  .border-bottom {
    border-bottom: none !important;
  }
}

@media (max-width: 492px) {
  .header-news {
    display: block;
    text-align: center;
  }
  .box-news-mobile {
    padding-bottom: 20px;
  }
  .border-bottom {
    border-bottom: 2px solid #EF3E17 !important;
  }
}
</style>