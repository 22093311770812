<template>
  <div class="py-60">
    <div class="container" id="Services">
      <div class="header-section">
        <h2 class="display-2 font-noto font-700 text-primary-1">บริการของเรา (Services)</h2>
      </div>
      
      <!-- desktop -->
      <div class="d-none d-md-block">
        <div class="row row-eq-height">
          <div class="col-12 col-md-6 mb-20" data-aos="fade-up" data-aos-delay="100">
            <div class="box-white">
              <i class="fa-solid fa-briefcase icon-setup"></i>
              <h3 class="display-3 font-public text-primary-1 font-500">Fundraising & Pre-IPO</h3>
              <p class="mb-0 font-noto">บริการวางแผนให้คำปรึกษาด้านการ<span>เพิ่มมูลค่าบริษัท</span> อันนำไปสู่การ<span>ระดมทุนจากนักลงทุน</span> เพื่อให้ตอบโจทย์ต่อวัตถุประสงค์ของการระดมทุน และวางโครงสร้างระบบงานที่จำเป็นต่อการระดมทุน และแต่งตัวเข้าตลาดหลักทรัพย์</p>
            </div>
          </div>

          <div class="col-12 col-md-6 mb-20" data-aos="fade-up" data-aos-delay="200">
            <div class="box-white">
              <i class="fa-solid fa-handshake-angle icon-setup"></i>
              <h3 class="display-3 font-public text-primary-1 font-500">Consulting Solutions</h3>
              <p class="mb-0 font-noto">บริการให้คำปรึกษาธุรกิจด้าน <span>การบริหาร การตลาด การสร้างแบรนด์ การเงินและการบัญชี งานบริหารทรัพยากรบุคคล</span> เพื่อยกระดับธุรกิจ และช่วยเพิ่มประสิทธิภาพการทำงานขององค์กร โดยผู้เชี่ยวชาญที่อยู่เบื้องหลังการเติบโตขององค์ต่างๆ มากมาย</p>
            </div>
          </div>

          <div class="col-12 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div class="box-white">
              <i class="fa-solid fa-users icon-setup"></i>
              <h3 class="display-3 font-public text-primary-1 font-500">Process Improvement</h3>
              <p class="mb-0 font-noto">วิเคราะห์ระบบ และ<span>กระบวนการทำงานแบบ 360 องศา</span> เพื่อวิเคราะห์ความเสี่ยง และจุดแข็ง-จุดอ่อน ของกระบวนการ และระบบที่ใช้อยู่ในปัจจุบัน แนะนำวิธีการปรับปรุงกระบวนการทำงาน ให้มีประสิทธิภาพสูงสุด เกิดผลลัพธ์ และสร้างการเปลี่ยนแปลงได้จริง</p>
            </div>
          </div>

          <div class="col-12 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <div class="box-white">
              <i class="fa-solid fa-laptop-code icon-setup"></i>
              <h3 class="display-3 font-public text-primary-1 font-500">Integrate and Implement System</h3>
              <p class="mb-0 font-noto">วางแผนการนำ<span>ระบบที่สามารถตอบโจทย์เฉพาะด้าน</span>ของธุรกิจ เข้ามาใช้ในจุดต่างๆ เพื่อให้การทำงาน และการบริหารจัดการเชื่อมโยงถึงกัน สามารถตรวจสอบ และดูรายงานผลของการทำงานด้านต่าง ๆ ที่บริษัทต้องการได้</p>
            </div>
          </div>
        </div>
      </div>

      <!-- mobile -->
      <div class="d-block d-md-none">
        <div class="box-carousel" v-on:scroll.passive="handleScroll">

          <div class="box-white" data-aos="fade-up" data-aos-delay="100">
            <i class="fa-solid fa-briefcase icon-setup"></i>
            <h3 class="display-3 font-public text-primary-1 font-500">Fundraising & Pre-IPO</h3>
            <p class="mb-0 font-noto">บริการวางแผนให้คำปรึกษาด้านการ<span>เพิ่มมูลค่าบริษัท</span> อันนำไปสู่การ<span>ระดมทุนจากนักลงทุน</span> เพื่อให้ตอบโจทย์ต่อวัตถุประสงค์ของการระดมทุน และวางโครงสร้างระบบงานที่จำเป็นต่อการระดมทุน และแต่งตัวเข้าตลาดหลักทรัพย์</p>
          </div>
          <div class="box-white" data-aos="fade-up" data-aos-delay="200">
            <i class="fa-solid fa-handshake-angle icon-setup"></i>
            <h3 class="display-3 font-public text-primary-1 font-500">Consulting Solutions</h3>
            <p class="mb-0 font-noto">บริการให้คำปรึกษาธุรกิจด้าน <span>การบริหาร การตลาด การสร้างแบรนด์ การเงินและการบัญชี งานบริหารทรัพยากรบุคคล</span> เพื่อยกระดับธุรกิจ และช่วยเพิ่มประสิทธิภาพการทำงานขององค์กร โดยผู้เชี่ยวชาญที่อยู่เบื้องหลังการเติบโตขององค์ต่างๆ มากมาย</p>
          </div>
          <div class="box-white" data-aos="fade-up" data-aos-delay="300">
            <i class="fa-solid fa-users icon-setup"></i>
            <h3 class="display-3 font-public text-primary-1 font-500">Process Improvement</h3>
            <p class="mb-0 font-noto">วิเคราะห์ระบบ และ<span>กระบวนการทำงานแบบ 360 องศา</span> เพื่อวิเคราะห์ความเสี่ยง และจุดแข็ง-จุดอ่อน ของกระบวนการ และระบบที่ใช้อยู่ในปัจจุบัน แนะนำวิธีการปรับปรุงกระบวนการทำงาน ให้มีประสิทธิภาพสูงสุด เกิดผลลัพธ์ และสร้างการเปลี่ยนแปลงได้จริง</p>
          </div>
          <div class="box-white" data-aos="fade-up" data-aos-delay="400">
            <i class="fa-solid fa-laptop-code icon-setup"></i>
            <h3 class="display-3 font-public text-primary-1 font-500">Integrate and Implement System</h3>
            <p class="mb-0 font-noto">วางแผนการนำ<span>ระบบที่สามารถตอบโจทย์เฉพาะด้าน</span>ของธุรกิจ เข้ามาใช้ในจุดต่างๆ เพื่อให้การทำงาน และการบริหารจัดการเชื่อมโยงถึงกัน สามารถตรวจสอบ และดูรายงานผลของการทำงานด้านต่าง ๆ ที่บริษัทต้องการได้</p>
          </div>
        </div>

        <!-- scroll -->
        <div class="gray-bar d-block d-lg-none mb-20">
          <div class="active-bar" :style="{'left': scrollPosition+'px'}"></div>
        </div>
      </div>
      


    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        scrollPosition: 0,
      }
    },
    methods: {
      handleScroll(e) {
        const current = e.target.scrollLeft
        const clientWidth = e.target.clientWidth
        const width = e.target.scrollWidth - clientWidth

        const scrollWidth = (clientWidth * 0.9) - 100
        this.scrollPosition = parseInt((current * scrollWidth) / width)
      },
    },
  }
</script>

<style lang="scss" scoped>
.header-section {
  border-bottom: 2px solid #EF3E17;
  padding-bottom: 30px;
  margin-bottom: 20px;
}
.box-white {
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 10px;
  padding: 40px;
  height: 100%;
}
i {
  color: #EF3E17;
}
.icon-setup {
  font-size: 40px;
  color: #EF3E17;
  margin-bottom: 10px;
}
span {
  font-family: 'Noto Sans', 'Noto Sans Thai', sans-serif;
  font-weight: 700;
}

@media (max-width: 768px) {
  .box-white {
    padding: 30px;
  }
  .display-2 {
    font-size: 24px;
  }
}

@media (max-width: 492px) {
  .header-section {
    padding-bottom: 15px;
  }

  // box carousel
  .box-carousel{
    white-space: nowrap;
    overflow-x: auto;
  }
  .box-white {
    display: inline-block;
    width: 300px;
    margin-right: 15px;
    white-space: normal;
    padding: 25px 20px 20px 20px;
    min-height: 350px;
    vertical-align: top;

    &:last-child {
      margin-right: 0px;
    }
  }
  .gray-bar {
    position: relative;
    background: #E8E8E8;
    border-radius: 100px;
    height: 7px;
    width: 90%;
    margin: 30px auto 0px;
  }
  .active-bar {
    width: 100px;
    height: 7px;
    background: #727272;
    border-radius: 100px;
    position: absolute;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
</style>