<template>
  <div>
    <MarqueeText class="mb-60">
      <div class="box-title">
        <h3 class="special-3 text-primary-1 text-title">OUR PARTNERS</h3>
        <h3 class="special-3 text-primary-1 text-title">OUR PARTNERS</h3>
        <h3 class="special-3 text-primary-1 text-title">OUR PARTNERS</h3>
        <h3 class="special-3 text-primary-1 text-title">OUR PARTNERS</h3>
        <h3 class="special-3 text-primary-1 text-title">OUR PARTNERS</h3>
        <h3 class="special-3 text-primary-1 text-title">OUR PARTNERS</h3>
      </div>
    </MarqueeText>


    <div class="text-center position-relative">
      <div class="marquee-overlay"></div>
      <MarqueeText class="mb-30" :duration="30">
        <div class="card-partner"><div><img src="../../assets/images/partners/1-logo-kkp.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/2-logo-cimb-thai.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/3-logo-cimb-auto.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/4-logo-uob.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/5-logo-bay.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/6-logo-tisco.png" alt="" class="w-100"></div></div>
      </MarqueeText>
      <MarqueeText class="mb-30" :duration="30" :reverse="true">
        <div class="card-partner"><div><img src="../../assets/images/partners/7-logo-tisco-ins.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/8-logo-tcr.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/9-logo-lh.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/10-logo-kl.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/11-logo-scb.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/12-logo-scbx.png" alt="" class="w-100"></div></div>
      </MarqueeText>
      <MarqueeText class="mb-30" :duration="30">
        <div class="card-partner"><div><img src="../../assets/images/partners/13-logo-bam.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/14-logo-quick.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/15-logo-heng.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/16-logo-kashjoy.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/17-logo-ocean-life.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/18-logo-tqm.png" alt="" class="w-100"></div></div>
      </MarqueeText>
      <MarqueeText :duration="30" :reverse="true">
        <div class="card-partner"><div><img src="../../assets/images/partners/19-logo-propertyjibjib.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/20-logo-funding-societies.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/21-logo-sc-asset.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/22-logo-ais.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/23-logo-wci.png" alt="" class="w-100"></div></div>
        <div class="card-partner"><div><img src="../../assets/images/partners/24-logo-acctwise.png" alt="" class="w-100"></div></div>
      </MarqueeText>
    </div>
    
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.box-title {
  border-top: 2px solid #EF3E17;
  border-bottom: 2px solid #EF3E17;
}
.text-title {
  display: inline-block;
  margin-right: 30px;
}
.card-partner {
  width: 300px;
  height: 180px;
  background: #2B2B2B;
  border-radius: 10px;
  margin-right: 30px;
  display: inline-block;
  padding: 20px;
}
.card-partner div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.position-relative {
  position: relative;
}
.marquee-overlay {
  background: linear-gradient(90deg, rgb(23, 23, 23) 0%, rgba(23, 23, 23, 0) 15%, rgba(23, 23, 23, 0) 85%, rgb(23, 23, 23) 100%);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
</style>