<template>
  <div class="pt-60">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="display-footer">
            <div class="left-footer">
              <img src="@/assets/images/consult/logo-finnx-consulting-group.svg" alt="logo_finnx_consulting">
            </div>
            <div>
              <div class="menu-footer">
                <div class="">
                  <div class="menu-item active"><a class="a-menu-item active" href="#Consulting">Consulting</a></div>
                  <div class="menu-item"><a class="a-menu-item" href="#Services">Services</a></div>
                  <div class="menu-item"><a class="a-menu-item" href="#Professionals">Professionals</a></div>
                  <div class="menu-item"><a class="a-menu-item" href="#Customer">Customer’s Voice</a></div>
                  <div class="menu-item"><a class="a-menu-item" href="#GetInTouch">Get In Touch</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
* {
  font-family: 'Public Sans', sans-serif;
  font-weight: 500 !important;
}
.display-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.active {
  color: #EF3E17 !important;
}
.a-menu-item {
  text-decoration: none;
  color: #FFFFFF;

  &:hover {
    color: #EF3E17;
  }
}
.menu-item {
  display: inline-block;
  margin-right: 30px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}
@media (max-width: 768px) {
  .menu-item {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 492px) {
  .menu-item {
    margin-right: 0;
    display: block;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .display-footer {
    display: block;
    text-align: center;
  }
  .left-footer {
    margin-bottom: 20px;
  }
}
</style>