<template>
  <div class="py-50">
    <div class="container">
      <div class="header-section">
        <h2 class="display-2 font-noto font-700 text-primary-1">Blog</h2>
        <a href="https://www.facebook.com/FinnxConsulting/" target="_blank"><p class="mb-0 font-noto text-primary-1 text-end position-blog-more">อ่านทั้งหมดได้ที่<br>Facebook Fan Page</p></a>
      </div>

      <!-- desktop -->
      <div class="d-none d-lg-block">
        <div class="row row-eq-height">
          <div class="col-12 col-md-4" v-for="article in articleArr" :key="'desktop'+article.id">
            <div class="box-article" @click="gotoArticle(article.url)">
              <div>
                <img width="100%" class="img-section" :src="article.img" alt="">
              </div>
              <div class="text-section">
                <h3 class="display-3 text-primary-1 font-noto font-700 mb-10">{{ article.title }}</h3>
                <p class="mb-0 font-noto">{{ article.desc }} <a class="font-noto text-primary-1" :href="article.url" target="_blank">อ่านต่อ</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ipad mobile -->
      <div class="d-block d-lg-none">
        <div class="box-carousel" v-on:scroll.passive="handleScroll">
          <div class="box-article" @click="gotoArticle(article.url)" v-for="article in articleArr" :key="'mobile'+article.id">
            <div>
              <img width="100%" class="img-section" :src="article.img" alt="">
            </div>
            <div class="text-section">
              <h3 class="display-3 text-primary-1 font-noto font-700 mb-10">{{ article.title }}</h3>
              <p class="mb-0 font-noto">{{ article.desc }} <a class="font-noto text-primary-1" :href="article.url" target="_blank">อ่านต่อ</a></p>
            </div>
          </div>
        </div>

        <!-- scroll -->
        <div class="gray-bar d-block d-lg-none mb-20">
          <div class="active-bar" :style="{'left': scrollPosition+'px'}"></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import article01 from '@/assets/images/consult/article_expand_people.jpg'
import article02 from '@/assets/images/consult/article_scale_business.jpg'
import article03 from '@/assets/images/consult/article_decision.jpg'
  export default {
    data() {
      return {
        scrollPosition: 0,
        articleArr: [
          {
            'id': 1,
            'img': article01,
            'title': '3 ข้อชวนคิด !! ก่อนธุรกิจ จะขยายสาขา',
            'desc': 'จากประสบการณ์ตรงที่คุณนพเคยไปช่วยบริหารร้านอาหาร และคลินิกที่มีแผนขยายสาขา',
            'url': 'https://www.facebook.com/100068724295675/posts/pfbid021bYq5yKPPJtvrA7VEwLtJ8VzyUyweUo74eje9g9PAmTBkjA8YhX9SBXJEEfqPbaDl/'
          },
          {
            'id': 2,
            'img': article02,
            'title': 'ธุรกิจขยาย จ้างคนเพิ่ม แต่กำไรลดลง',
            'desc': 'ยิ่งขยาย ยิ่งขาดทุน เป็นปัญหาปกติที่พบเจอ ในธุรกิจที่ (เคย) ไปได้ดี และกำลังเติบโต',
            'url': 'https://fb.watch/ksjGMLyFlH'
          },
          {
            'id': 3,
            'img': article03,
            'title': 'Data นำมาปรับปรุงธุรกิจได้อย่างไร',
            'desc': 'หลายคนอาจจะยังไม่รู้ว่า Data มีความสำคัญกับธุรกิจอย่างไร นำมาปรับใช้อย่างไรได้บ้าง',
            'url': 'https://www.facebook.com/FinnxConsulting/posts/pfbid0226J5hCzeinLX8CzPaGvCfFTSx5WetZijZaiZMSfJh3A7SfvouGrSQe574744Hu3cl'
          },
        ]
      }
    },
    methods: {
      handleScroll(e) {
        const current = e.target.scrollLeft
        const clientWidth = e.target.clientWidth
        const width = e.target.scrollWidth - clientWidth

        const scrollWidth = (clientWidth * 0.9) - 100
        this.scrollPosition = parseInt((current * scrollWidth) / width)
      },
      gotoArticle(link) {
        window.open(link, '_blank');
      }
    },
  }
</script>

<style lang="scss" scoped>
.header-section {
  position: relative;
}
.position-blog-more {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 700;
}
.box-article {
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 0px 0px 10px 10px;
  border-radius: 10px;
  height: 100%;
}
.img-section {
  border-radius: 10px 10px 0px 0px;
  height: 220px;
  object-fit: cover;
}
.text-section {
  border-radius: 0px 0px 10px 10px;
  background-color: #FFF;
  padding: 20px;
  width: 100%;
}
@media (max-width: 768px) {
  .position-blog-more {
    position: absolute;
    top: -10px;
  }

  // box carousel
  .box-carousel{
    white-space: nowrap;
    overflow-x: auto;

    padding-bottom: 15px;
  }
  .box-article {
    display: inline-block;
    width: 350px;
    margin-right: 15px;
    white-space: normal;
    min-height: 350px;
    vertical-align: top;

    &:last-child {
      margin-right: 0px;
    }
  }
  .gray-bar {
    position: relative;
    background: #E8E8E8;
    border-radius: 100px;
    height: 7px;
    width: 90%;
    margin: 30px auto 0px;
  }
  .active-bar {
    width: 100px;
    height: 7px;
    background: #727272;
    border-radius: 100px;
    position: absolute;
  }

  ::-webkit-scrollbar {
    display: none;
  }

}

@media (max-width: 492px) {
  .position-blog-more {
    position: absolute;
    top: -10px;
    font-weight: 400;
    font-size: 12px;
  }
  .box-article {
    width: 300px;
  }
}
</style>