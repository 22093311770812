import Vue from 'vue'
import App from './App.vue'
import 'bootstrap'
import VueRouter from 'vue-router';
import routers from './routers';

Vue.use(VueRouter)

const routerSetup = new VueRouter({
  routes: routers,
  mode: 'history'
})

// setup title
routerSetup.beforeEach((to, from, next) => {
  // console.log(to);
  document.title = `${ to.name }`
  next()
})

import "./assets/scss/variales.scss";
import "./assets/scss/style.scss"

import "@fortawesome/fontawesome-free/css/all.css"
import "@fortawesome/fontawesome-free/js/all.js"

import MarqueeText from 'vue-marquee-text-component'

Vue.component('MarqueeText', MarqueeText)

import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router: routerSetup,
  mounted() {
    AOS.init()
  },
}).$mount('#app')
