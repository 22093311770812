<template>
  <div class="pt-60 pb-30">
    <div class="container" id="Customer">
    <div class="header-section">
      <h2 class="display-2 font-noto font-700 text-primary-1">Customer’s Voice</h2>
    </div>
    
    <div class="box-white mb-30" data-aos="fade-up" data-aos-delay="100" v-for="customer in customerArr" :key="'customer'+customer.customer_id">
      <div class="row text-center text-md-start">
        <div class="col-12 col-md-4">
          <img class="img-customer mb-20" :src="customer.customer_img" alt="">
          <p class="display-3 text-primary-1 font-noto font-700" v-html="customer.customer_name"></p>
          <hr class="hr-line">
          <p class="display-p mb-10 font-noto font-700">{{ customer.customer_brand_title }}</p>
          <p class="display-p mb-0 font-noto">{{ customer.customer_brand_desc }}</p>
        </div>
        <div class="col-12 col-md-8">
          <p class="display-3 text-primary-1 font-noto font-700 mb-20">{{ customer.customer_review_title }}</p>
          <p class="display-p font-noto" v-html="customer.customer_review_desc"></p>
        </div>
      </div>
    </div>
       
        
    </div>
  </div>
</template>

<script>
import customer_war from '@/assets/images/consult/customer-warrix.png'
import customer_inn from '@/assets/images/consult/customer-innovative.png'
import customer_ide from '@/assets/images/consult/customer-ideal-and-marvelous-ten.png'
import customer_fam from '@/assets/images/consult/customer-family-amusement.png'
import customer_hua from '@/assets/images/consult/customer-hua-gruay.png'
  export default {
    data() {
      return {
        customerArr: [
          {
            'customer_id': 1,
            'customer_img': customer_war,
            'customer_name': 'คุณณัฐกมล วงษ์สด <br>(Vice President)',
            'customer_brand_title': 'Warrix Sport PCL.',
            'customer_brand_desc': 'ธุรกิจจำหน่ายเสื้อกีฬา เสื้อเชียร์ทีมชาติไทย',
            'customer_review_title': '“Key Matrix ต่างๆ เป็นสารตั้งต้น”',
            'customer_review_desc': `ธุรกิจของเราใช้ Data ค่อนข้างเยอะ ปัญหาที่พบจากการขายหลาย Channel และสินค้าหลากหลาย ทําให้จัดการข้อมูลยาก พอได้ปรึกษาแล้ว<span>ได้ประโยชน์ในส่วนของ 
            Key Matrix ต่างๆ เป็นสารตั้งต้น</span> ให้เราคิดว่าเราควรนึกถึงอะไรบ้าง และควรทําอย่างไร ในแต่ละด้านจะนําไปปรับใช้กับแต่ละแผนก ตั้งตัวชี้วัดยังไงให้ได้ผล`
          },
          {
            'customer_id': 2,
            'customer_img': customer_inn,
            'customer_name': 'คุณวัชรพงษ์ เจริญถนอม',
            'customer_brand_title': 'บริษัท อินโนเวทีฟ อินสทรูเมนต์ จํากัด',
            'customer_brand_desc': 'ธุรกิจจัดจำหน่าย และให้บริการเครื่องมือตรวจวัดทางด้าอาชีวอนามัย',
            'customer_review_title': '“เทรนด์โลกเปลี่ยนเทรนด์ไปเรื่อยๆ”',
            'customer_review_desc': `ปัญหาหลักที่เราเจอ คือ เทรนด์โลกเปลี่ยนเทรนด์ไปเรื่อยๆ ข้อมูลมีความจําเป็นมากที่จะช่วยว่าธุรกิจเราตอบโจทย์ลูกค้าหรือเปล่า 
            ส่วนตัวคิดว่า <span>Data Matrix มาช่วยเรื่องการนําข้อมูลมาวิเคราะห์</span> 
            รวมถึงการค้นหา ข้อมูลอย่างมีหลักการ <span>จึงช่วยตอบโจทย์การพัฒนาธุรกิจของเรา</span>`
          },
          {
            'customer_id': 3,
            'customer_img': customer_ide,
            'customer_name': 'คุณทวาทัพพ์ <br>ธรารักษ์อนันต์',
            'customer_brand_title': 'บริษัท ไอดีล แอนด์ มาเวลลัส เท็น จํากัด',
            'customer_brand_desc': 'ธุรกิจแบรนด์เครื่องสําอาง และแบรนด์เสื้อผ้าแฟชั่นผู้หญิง',
            'customer_review_title': '“ผมรู้สึกว่า ผมปลดล็อคสําหรับเรื่องนี้เลย”',
            'customer_review_desc': `
            สิ่งที่ผมคาดหวังก็คือเรื่องระบบต่างๆ <br><br>

            ผมต้องการที่จะสเกลในเรื่องของพนักงาน แต่สิ่งที่ผมว้าวแล้ว ผมรู้สึกว่าเป็นเรื่องที่ดีมาก คือ <span>เรื่องของ HR ตอนแรกผมมี Pain Point ในส่วนนี้</span> ผมคิดว่าคนเก่งสามารถที่จะเป็นหัวหน้าได้สิ
            แต่จริงๆ แล้ว ไม่ใช่ ผมใส่แรงกดดันไป ใส่ Project ต่างๆ ไปให้เขา <br><br>

            สุดท้ายเขาทําไม่ได้แล้วเขารู้สึกว่าเขากดดัน จากนั้นเขาออกไป พูดตรงๆ ผมไม่รู้ว่าเหตุผลทําไมมันถึงเป็นแบบนี้ <br><br>

            <span>จนผมได้รู้คําตอบครับ</span> ว่าคนเก่งๆ ส่วนใหญ่บางที่เขาไม่ได้ต้องการจะเป็นหัวหน้า แต่เขาต้องการที่จะเป็น Specialist ในแต่ละสายตรงนั้นต่างหาก ผมรู้สึกว่า ผมปลดล็อคสําหรับเรื่องนี้เลย ผมคิดว่า ผมน่าจะเอาไปแชร์ให้กับทีมงาน หรือบุคลากรที่ผมดูแลอยู่ได้ครับ
            `
          },
          {
            'customer_id': 4,
            'customer_img': customer_fam,
            'customer_name': 'คุณสรวิศ ปัญจมณีโชติ',
            'customer_brand_title': 'บริษัท แฟมมิลี่ อะมิวส์เม้นท์ จํากัด',
            'customer_brand_desc': 'ธุรกิจสวนสนุกในห้างสรรพสินค้า',
            'customer_review_title': '“ผมว่าโชคดีมากๆ ที่ติดตามกับอาจารย์นพมานาน”',
            'customer_review_desc': `ปัจจุบันผมเป็น CEO อยู่บริษัท แฟมมิลี่ อะมิวส์เม้นท์ จํากัดนะครับ ทําธุรกิจเกี่ยวกับสวนสนุกในห้างสรรพสินค้าครับ เป็นแบบ FEC (Family Entertainment Center) เป็นเหมือนธุรกิจความบันเทิงของครอบครัวนะครับ <span>ผมว่าโชคดีมากๆ  ติดตามอาจารย์นพมานานครับ</span> ก็ฟังอาจารย์ตลอด เพราะว่าผมมีแผนอยากจะ Exit จากธุรกิจ เราจะทํายังไงกับกับธุรกิจ แต่อาจารย์ทําให้พี่คิดและตอบทุกอย่างได้ ไม่ว่าจะเป็น <br><br>

            เรื่องของกลยุทธ์ที่ต้องเอาไปปรับใช้ แล้วก็เรื่องของการวางแผน การทํา Flow <span>ได้ความรู้เอาไปปรับใช้กับธุรกิจได้เยอะเลยครับผม</span> <br><br>

            ถ้าให้แนะนํากับเพื่อนๆ ผมว่าอยากให้เพื่อนๆ ที่เป็นผู้บริหารลองเข้ามาสัมผัสดู อาจารย์ก็เหมือน เล่าให้เราฟังตั้งแต่ต้นว่าหน้าที่ของ CEO คุณต้องทําอะไร ต้องมี Vision ยังไง ต้องมีสถิติยังไง เพื่อให้เราเติบโตได้ยังไง ต้องมีทั้งการสร้าง S Curve ผมว่าเป็นประโยชน์มากๆ ครับ `
          },
          {
            'customer_id': 5,
            'customer_img': customer_hua,
            'customer_name': 'คุณเฟิร์ส <br>เจษฎา วราสินธุ์',
            'customer_brand_title': 'บริษัท หัวกรวย สตูดิโอ จํากัด',
            'customer_brand_desc': 'Content Creator และธุรกิจร้านตัดผม',
            'customer_review_title': '“มันตอบโจทย์ และจี้ปมผม”',
            'customer_review_desc': `
            ผมทํา Channel YouTube เป็น Creator แล้วก็เปิดร้านตัดผม ต้องการที่จะสเกลธุรกิจ แต่สร้างมาเป็น Personal Brand เป็นหลัก มันก็เลยไม่สามารถสเกลได้ในตอนนี้ <span>ถ้าถามว่า มันตรงประเด็นใหม ก็ตรงมากๆ เลยครับ</span> คือ อยากจะแก้อะไร วางระบบภายในได้ แล้วไปไหนต่อ เราก็จะรู้ว่าเดินไปทิศไหน เพื่อให้มองเห็นอนาคต มองเห็นวิสัยทัศน์ขององค์กร มันก็แก้ได้ตรงจุดเลยครับ <br><br>

            ส่วนที่ชอบมากที่สุด ผมเรียกว่า ส่วนธรรมะ ผมจําง่าย เขาเรียกว่า New S Curve ครับ ทุกธุรกิจมันมีเกิดขึ้น ตั้งอยู่แล้วก็ดับไป แต่สิ่งที่เราไม่ได้สังเกตก็คือ มันดับไปไหน มันดับไปสูงขึ้น หรือดับไปเลยจริงๆ <span>มันตอบโจทย์ และจี้ปมผม</span> คือ
            มันดับไปแล้วทําไมเราไม่สร้าง ถ้าสร้างแล้วไม่ Stable ก็สร้างใหม่นั่นเอง แบบนี้ครับผม มันก็จะตรงจุดครับ <br><br>

            ถ้าทําธุรกิจอย่างเศสผมองค์กรที่ไม่เป็นระบบแก้ยากกว่าองค์กรที่ถูกเซ็ตขึ้นมาอย่างดี
            เพราะผมรู้สึกว่าแต่ละคนมันมีเส้นบางๆในการที่จะให้เขาเปลี่ยนมาเป็นในรูปแบบที่อยู่ในระบบมันยากกว่า
            `
          },
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>
.box-white {
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 10px;
  padding: 50px 70px;
}
.img-customer {
  width: 165px;
}
.hr-line {
  border-top: 2px solid #EF3E17;
  opacity: 1;
}

@media (max-width: 768px) {
  .box-white {
    padding: 40px 35px;
  }
  .display-3 {
    font-size: 24px;
  }
}
</style>