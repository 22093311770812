<template>
  <div>
    <div class="text-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h3 class="special-3 text-primary-1 mb-30">GET YOUR TICKET, <br>PASSENGER!</h3>
            
            <!-- <div class="row">
              <div class="col-12 col-lg-9 padding-left-card">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laudantium cum vitae laborum, eum consequatur commodi autem omnis ipsam id beatae corporis culpa animi, nisi exercitationem eligendi suscipit fuga iure similique!
              </div>
              <div class="col-12 col-lg-3 padding-left-card">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae recusandae fugiat explicabo excepturi tenetur id, facere ea reiciendis libero molestias vero corporis illum pariatur eum repellendus fugit et, impedit omnis!
              </div>
            </div> -->
            <div class="left-ticket">
              <h3 class="special-3">GET IN TOUCH</h3>
              <p class="special-p mb-20">CONTACT@FINNX.IO</p>
              <hr class="hr-ticket mb-50">
              <h4 class="special-4">FINN X Co., LTD</h4>
              <p class="display-sm">213/2 Asoke Towers (3rd Floor), Sukhumvit 21 Road, 
                <br>Khlong Toei Nuea, Wattana, Bangkok 10110
              </p>
            </div>
            <a class="text-white" href="mailto:contact@finnx.io">
              <div class="right-ticket">
                <h4 class="special-4">SEND A MESSAGE</h4>
              </div>
            </a>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.left-ticket, .right-ticket {
  height: 300px;
  box-sizing: border-box;
  border-radius: 10px;
  display: inline-block;
  vertical-align: top;
}
.left-ticket {
  width: 585px;
  background: #2B2B2B;
  border: 1px solid #727272;
  padding: 40px;
  text-align: left;
}
.right-ticket {
  width: 165px;
  background: #EF3E17;
  padding: 35px;
}
.right-ticket h4 {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.hr-ticket {
  border: 1px dashed #727272;
}


@media (max-width: 768px) {
  .left-ticket {
    width: 440px;
  }
}

@media (max-width: 492px) {
  .left-ticket, .right-ticket {
    padding: 20px;
  }
  .left-ticket {
    width: 100%;
    height: auto;
  }
  .right-ticket {
    width: 100%;
    height: 130px;
  }
}
</style>