<template>
  <div>
    <div class="bg-header">
      <Navbar />

      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-11 col-xl-10">

            <div class="text-header text-center">
              <div class="">
                <div class="text-first-line">
                  <h1 class="special-1 text-primary-1 " 
                  data-aos="fade-left"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="300"
                  data-aos-duration="1300">EXPON</h1>
                </div>
                <div class="text-first-line">
                  <h1 class="special-1 text-primary-1 " 
                  data-aos="fade-right"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="300"
                  data-aos-duration="1300">ENTIAL</h1>
                </div>
              </div>
              <div class="">
                <div class="text-second-line">
                  <h1 class="special-1 text-primary-1 " 
                  data-aos="fade-left"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="300"
                  data-aos-duration="1300">YOUR</h1>
                </div>
                <div class="text-second-line">
                  <h1 class="special-1 text-primary-1 " 
                  data-aos="fade-right"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="300"
                  data-aos-duration="1300">BUSINESS</h1>
                </div>
              </div>

              <div class="hr-small" 
              data-aos="fade-up" 
              data-aos-duration="1000"
              data-aos-delay="700"></div>

              <div class="">
                <div class="text-third-line">
                  <div class="text-sub-header" 
                  data-aos="fade-left"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="300"
                  data-aos-duration="2000">Results-Driven by FINNX</div>
                </div>
                <div class="text-third-line">
                  <div class="text-sub-header" 
                  data-aos="fade-right"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="300"
                  data-aos-duration="2100">Consulting Group</div>
                </div>
              </div>
               
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Navbar from '../layouts/consult/Navbar.vue'
  export default {
    components: {
      Navbar,
    },
  }
</script>

<style lang="scss" scoped>
* {
  font-family: 'Public Sans', sans-serif;
  font-weight: 300;
}
.bg-header {
  background-image: linear-gradient(180deg, rgba(23,23,23,1) 0%, rgba(23,23,23,0) 50%, rgba(23,23,23,1) 100%), url('../../assets/images/consult/bg-consult.png');
  height: 950px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0px;
}
.text-header {
  position: relative;
  top: 120px;
}

.text-first-line {
  display: inline-block;
  overflow: hidden;
  width: fit-content;
}
.text-second-line:first-child {
  margin-right: 10px;
}
.text-second-line:first-child {
  display: inline-block;
  overflow: hidden;
  width: fit-content;
  margin-right: 30px;
}
.text-second-line:last-child {
  display: inline-block;
  overflow: hidden;
  width: fit-content;
}
.text-third-line:first-child {
  display: inline-block;
  width: fit-content;
  overflow: hidden;
  margin-right: 10px;
}
.text-third-line:last-child {
  display: inline-block;
  width: fit-content;
  overflow: hidden;
}
.hr-small {
  border-top: 4px solid #EF3E17;
  width: 120px;
  display: block;
  margin: 0px auto 20px;
}
.text-sub-header {
  font-size: 30px;
  color: #FFF;
}


@media (max-width: 1024px) {
  .text-header {
    top: 85px;
  }
}
@media (max-width: 768px) {
  .bg-header {
    height: 600px;
    padding: 20px 0px;
  }
  .text-header {
    top: 60px;
  }
  .special-1 {
    font-size: 40px;
  }
  .text-sub-header {
    font-size: 20px;
  }
}
@media (max-width: 492px) {
  .text-header {
    top: 60px;
  }
  .text-second-line:first-child {
    margin-right: 20px;
  }
  .bg-header {
    height: 900px;
  }
  .hr-small {
    margin: 10px auto 30px;
  }
}
</style>