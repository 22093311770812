<template>
  <div>
    <div class="bg-header">
      <Navbar />

      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-11 col-xl-10">

            <div class="text-header text-center">
              <div class="d-none d-lg-block">
                <h1 class="special-1">FINNX</h1>
                <h3 class="special-3">INNOVATION ♦ FINANCIAL ♦ TECHNOLOGY</h3>
              </div>
              <div class="d-none d-md-block d-lg-none">
                <h1 class="special-2">FINNX</h1>
                <h3 class="special-4">INNOVATION ♦ FINANCIAL ♦ TECHNOLOGY</h3>
              </div>
              <div class="d-block d-md-none">
                <h1 class="special-2">FINNX</h1>
                <h3 class="special-4">INNOVATION ♦ FINANCIAL <br> TECHNOLOGY</h3>
              </div>
            </div>

            <div class="text-go-next text-center">
              <h4 class="special-4 mb-20">Start Explore</h4>
              <div>
                <a href="#about"><i class="fa-solid fa-caret-down icon-go-next"></i></a>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Navbar from '../layouts/Navbar.vue'
  export default {
    components: {
      Navbar,
    },
  }
</script>

<style lang="scss" scoped>
.bg-header {
  background-image: linear-gradient(180deg, rgba(23,23,23,1) 0%, rgba(23,23,23,0) 50%, rgba(23,23,23,1) 100%), url('../../assets/images/bg/bg-finnx.jpeg');
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0px;
}
.text-header {
  position: relative;
  top: 160px;
}
.text-go-next {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}
.icon-go-next {
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  color:#EF3E17;
  border-radius:50%;
  animation: bounce .3s infinite alternate;
  cursor: pointer;
}
@keyframes bounce {
  to { transform: scale(1.2); }
}

@media (max-width: 1024px) {
  .text-header {
    top: 285px;
  }
}
@media (max-width: 768px) {
  .bg-header {
    padding: 20px 0px;
  }
  .text-header {
    top: 220px;
  }
}
@media (max-width: 492px) {
  .text-header {
    top: 130px;
  }
}
</style>