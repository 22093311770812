<template>
  <div class="bg-blackhole text-white">
    <Header id="home"/>
    <About class="pt-80 mb-80 margin-about" id="about"/>
    <VisionAndMission id="visionandmission" class="mb-80" />
    <OurServices id="ourservices" class="mb-80"/>
    <News id="news" class="margin-section-news"/>
    <OurPartners class="mb-80" />
    <Ticket id="contact" class="padding-section-ticket" />
    <Footer />
  </div>
</template>

<script>
import Header from './Header.vue'
import About from './About.vue'
import VisionAndMission from './VisionAndMission.vue'
import OurServices from './OurServices.vue'
import News from './News.vue'
import OurPartners from './OurPartners.vue'
import Ticket from './Ticket.vue'
import Footer from '../layouts/Footer.vue'

export default {
  components: {
    Header,
    About,
    VisionAndMission,
    OurServices,
    News,
    OurPartners,
    Ticket,
    Footer
  },
}
</script>

<style lang="scss">
.margin-section-news {
  margin-bottom: 115px;
}
.padding-section-ticket {
  padding-bottom: 100px;
}
.margin-about {
  margin-top: -5px;
  background-color: #171717;
}

@media (max-width: 492px) {
  .margin-section-news {
    margin-bottom: 60px;
  }
}
</style>
