<template>
  <div>
    <div class="text-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
            <h3 class="special-3 text-primary-1 mb-30">OUR SERVICES</h3>
            
            <div class="row justify-content-center">
              <div class="col-12 mb-30">
                <div class="card-service-consult">
                  
                  <div class="row">
                    <div class="col-12 col-lg-4 col-xl-3 mb-10">
                      <img src="../../assets/images/logo/logo-finnx-consulting.png" class="mb-20" width="180" alt="logo-consult">
                      <a href="tel:+66653563246" class="d-none d-lg-block"><button class="btn btn-primary-1 font-noto text-white display-p w-100">ติดต่อสอบถาม</button></a>
                    </div>
                    <div class="col-12 col-lg-8 col-xl-9">
                      <p class="display-4 font-noto text-primary-1 text-center text-lg-start mb-10">ยกระดับธุรกิจของลูกค้า</p>
                      <p class="display-p font-noto text-center text-lg-start mb-20">ผู้ให้บริการด้านการเป็นที่ปรึกษาให้แก่องค์กรธุรกิจ ปรับปรุงกระบวนการบริหารจัดการ และวางระบบที่มีประสิทธิภาพ เพื่อให้ลูกค้าได้รับผลตอบแทนจากการลงทุนเป็นมูลค่านับไม่ถ้วน <br><br>

                      โดยมีทีมที่ปรึกษาที่มีความเชี่ยวชาญเฉพาะและมีประสบการณ์ในด้านต่าง ๆ มาอย่างยาวนาน เช่น การบริหาร การตลาด การเงิน การบัญชี เป็นต้น</p>
                      
                      <a href="tel:+66653563246" class="d-block d-lg-none"><button class="btn btn-primary-1 font-noto text-white display-p w-100 btn-mobile">ติดต่อสอบถาม</button></a>

                      <div class="display-box-consult">
                        <img class="display-img-consult" src="../../assets/images/ourservices/consult/consult-scg.png" alt="">
                        <img class="display-img-consult" src="../../assets/images/ourservices/consult/consult-cpall.png" alt="">
                        <img class="display-img-consult" src="../../assets/images/ourservices/consult/consult-penguin.png" alt="">
                        <img class="display-img-consult" src="../../assets/images/ourservices/consult/consult-rosegold.png" alt="">
                        <img class="display-img-consult" src="../../assets/images/ourservices/consult/consult-missgrand.png" alt="">
                        <!-- <img class="display-img-consult" src="../../assets/images/ourservices/consult/consult-bearhouse.png" alt=""> -->
                      </div>

                      <div class="display-box-consult-mobile">
                        <img class="display-img-consult" src="../../assets/images/ourservices/consult/consult-scg.png" alt="">
                        <img class="display-img-consult" src="../../assets/images/ourservices/consult/consult-penguin.png" alt="">
                        <img class="display-img-consult" src="../../assets/images/ourservices/consult/consult-rosegold.png" alt="">
                        <img class="display-img-consult" src="../../assets/images/ourservices/consult/consult-cpall.png" alt="">
                        <img class="display-img-consult" src="../../assets/images/ourservices/consult/consult-missgrand.png" alt="">
                        <!-- <img class="display-img-consult" src="../../assets/images/ourservices/consult/consult-bearhouse.png" alt=""> -->
                      </div>

                    </div>
                  </div>

                </div>
              </div>

              <div class="col-12 col-md-6 mb-30">
                <div class="card-service">
                  <img src="../../assets/images/logo/logo-refinn.svg" class="mb-30" alt="logo-refinn">
                  <p class="display-p font-noto">Refinn (รีฟินน์) FinTech Startup <br>ที่ช่วยคนไทยจัดการหนี้ และรีไฟแนนซ์ <br>ผู้สมัครใช้บริการแล้วกว่า 300,000 ครั้ง</p>
                  <a href="https://www.refinn.com/" target="_blank"><button class="btn btn-primary-1 font-noto btn-goto-site text-white display-p">เข้าสู่เว็บไซต์</button></a>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-30">
                <div class="card-service">
                  <h3 class="special-3 text-finnx-creator mb-30">FINNX Creators</h3>
                  <p class="display-p font-noto">Content Creator ของชาว FINNX <br> เพื่อเสนอความรู้ธุรกิจการเงิน</p>
                  <a href="https://www.labtang.com" target="_blank"><button class="btn btn-primary-1 font-noto btn-goto-site text-white display-p">เข้าสู่เว็บไซต์</button></a>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-30">
                <div class="card-service">
                  <img src="../../assets/images/logo/logo-finnprop.svg" class="mb-30" alt="logo-finnprop">
                  <p class="display-p font-noto">Platform อสังหาริมทรัพย์ <br>ให้บริการทั้งสินเชื่อโฉนดแลกเงิน <br>และให้บริการตัวแทนซื้อขายอสังหาฯ <br>แบบครบวงจร ด้วยเทคโนโลยีทันสมัย</p>
                  <a href="https://www.finnprop.com/" target="_blank"><button class="btn btn-primary-1 font-noto btn-goto-site text-white display-p">เข้าสู่เว็บไซต์</button></a>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-30">
                <div class="card-service">
                  <img src="../../assets/images/logo/logo-secondary-shieldx.png" width="160" class="mb-20" alt="logo-shieldx">
                  <p class="display-p font-noto">เพิ่มความปลอดภัยด้วย Email Security <br> ป้องกันการถูกโจมตีด้วยเทคโนโลยีจาก ShieldX <br> และมาตรฐานการป้องกันเพื่อคนไทย</p>
                  <a href="tel:+66653563246"><button class="btn btn-primary-1 font-noto btn-goto-site text-white display-p">ติดต่อสอบถาม</button></a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.font-noto {
  color: #FFF;
}
.card-service-consult {
  box-sizing: border-box;
  width: 100%;
  background: #2B2B2B;
  border: 1px solid #727272;
  border-radius: 10px;
  padding: 60px 50px;
  position: relative;
}
.card-service {
  box-sizing: border-box;
  width: 100%;
  height: 500px;
  /* based/galaxy */
  background: #2B2B2B;
  /* based/dusty */
  border: 1px solid #727272;
  border-radius: 10px;
  padding: 60px 50px;
  position: relative;
}
.btn-goto-site {
  width: 340px;
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
}
.text-finnx-creator {
  color: #4CBD99;
}
.text-shieldx {
  color: #8000FF;
}
.display-box-consult {
  display: flex;
  justify-content: space-between;
}
.display-img-consult {
  height: 75px;
  display: inline-block;
}
.display-box-consult-mobile {
  display: none;
}

@media (max-width: 1024px) {
  .display-img-consult {
    height: 50px;
  }
}

@media (max-width: 768px) {
  .btn-goto-site {
    width: 300px;
    bottom: 50px;
  }
  .display-img-consult {
    height: 75px;
  }
  .btn-mobile {
    width: 300px !important;
    margin-bottom: 20px;
  }
}

@media (max-width: 492px) {
  .card-service-consult {
    padding: 15px;
  }
  .display-box-consult {
    display: none;
  }
  .display-box-consult-mobile {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    justify-content: space-evenly;
  }
  .display-box-consult-mobile > img {
    margin-bottom: 15px;
  }
}
</style>