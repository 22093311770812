import { render, staticRenderFns } from "./VisionAndMission.vue?vue&type=template&id=2191c2a2&scoped=true&"
import script from "./VisionAndMission.vue?vue&type=script&lang=js&"
export * from "./VisionAndMission.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2191c2a2",
  null
  
)

export default component.exports