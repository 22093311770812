<template>
  <div class="setup-section">
    <div class="container" id="Professionals">
      <div class="box-white">
        <div class="header-section">
          <h2 class="display-2 font-noto font-700 text-primary-1">ผู้เชี่ยวชาญเฉพาะด้าน (Professionals)</h2>
        </div>
        <p class="mb-20 font-noto">การให้บริการด้านที่ปรึกษาของเรา ให้บริการโดย<span>ผู้เชี่ยวชาญที่มีประสบการณ์เฉพาะทางในด้านต่างๆ</span> เพื่อให้การช่วยแก้ปัญหาของลูกค้าเป็นไปอย่างราบรื่น และตอบโจทย์ความต้องการด้วยประสบการณ์การทำงานในอุตสาหกรรมต่างๆ มามากกว่า 10 ปี</p>

        <div class="row text-center text-md-start">
          <div class="col-12 col-md-6 mb-30 mb-md-20">
            <div class="row justify-content-center">
              <div class="col-8 col-md-4">
                <img width="100%" class="mb-20" src="@/assets/images/consult/consultant-pongsatorn.png" alt="">
                <img class="d-none d-md-block" width="100%" src="@/assets/images/consult/badge-harvardbusinessschool.png" alt="">
              </div>
              <div class="col-12 col-md-8">
                <p class="mb-0 font-noto font-700 text-primary-1">พงศธร ธนบดีภัทร <br>(Chief Executive Officer)</p>
                <p class="mb-10 mb-md-0 font-noto">
                  ผู้เชี่ยวชาญด้านการวางระบบบริหารจากประสบการณ์เป็นที่ปรึกษาให้กับบริษัทชั้นนำระดับประเทศ และยังได้รับการรับรองให้เป็น <span>ผู้เชี่ยวชาญด้านกลยุทธ์ธุรกิจ</span> (Specialization in Strategy) อย่างเป็นทางการจาก <span>Harvard Business School</span></p>
                  
                  <img class="d-block d-md-none mx-auto" width="165px" src="@/assets/images/consult/badge-harvardbusinessschool.png" alt="">
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 mb-30 mb-md-20">
            <div class="row justify-content-center">
              <div class="col-8 col-md-4">
                <img width="100%" class="mb-20" src="@/assets/images/consult/consultant-nuttapon.png" alt="">
              </div>
              <div class="col-12 col-md-8">
                <p class="mb-0 font-noto font-700 text-primary-1">ณัฏฐพล ผุสดีกุลไพศาล <br>(Principal Consultant)</p>
                <p class="mb-10 font-noto">
                  <span>ผู้สอบบัญชีรับอนุญาต (CPA)</span> และผู้เชี่ยวชาญด้านการวิเคราะห์ระบบการเงินและการบัญชี  </p>
                <img width="165px" src="@/assets/images/consult/badge-deloitte.png" alt="">
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 mb-30 mb-md-20">
            <div class="row justify-content-center">
              <div class="col-8 col-md-4">
                <img width="100%" class="mb-20" src="@/assets/images/consult/consultant-anathip.png" alt="">
              </div>
              <div class="col-12 col-md-8">
                <p class="mb-0 font-noto font-700 text-primary-1">อัณณ์อธิป กิตติจุฑานันท์ <br>(Principal Consultant)</p>
                <p class="mb-10 font-noto">
                  ผู้เชี่ยวชาญด้านการตลาด เพิ่มยอดขาย การสื่อสาร ภาพลักษณ์ เพื่อเป็น Professional Firm</p>
                <!-- <img width="120px" src="@/assets/images/consult/badge-aisthestartup.png" alt=""> -->
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 mb-30 mb-md-20">
            <div class="row justify-content-center">
              <div class="col-8 col-md-4">
                <img width="100%" class="mb-20" src="@/assets/images/consult/consultant-worayoot.png" alt="">
              </div>
              <div class="col-12 col-md-8">
                <p class="mb-0 font-noto font-700 text-primary-1">วรยุทธ เพชรสุวรรณรังษี <br>(Principal Consultant)</p>
                <p class="mb-10 font-noto">
                  ผู้เชี่ยวชาญด้านการวาง<span>ระบบโครงสร้างด้านเทคโนโลยี</span>ขององค์กร เพื่อบรรลุเป้าหมายที่องค์กรต้องการ</p>
                <img width="120px" src="@/assets/images/consult/badge-microsoft-for-startup.png" alt="">
              </div>
            </div>
          </div>
        </div>

      </div>  
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.setup-section {
  margin-top: -180px;
}
.box-white {
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 10px;
  padding: 60px 70px 30px;
}
.header-section {
  border-bottom: 2px solid #EF3E17;
  padding-bottom: 30px;
  margin-bottom: 20px;
}
span {
  font-family: 'Noto Sans', 'Noto Sans Thai', sans-serif;
  font-weight: 700;
}

@media (max-width: 1024px) {
  .box-white {
    padding: 60px 70px 30px;
  }
}

@media (max-width: 768px) {
  .box-white {
    padding: 30px 30px 20px;
  }
  .display-2 {
    font-size: 24px;
  }
}

@media (max-width: 492px) {
  .header-section {
    padding-bottom: 15px;
  }
  .display-2 {
    line-height: 120%;
  }
  .box-white {
    padding: 30px 20px 10px;
  }
}
</style>