
<template>
  <div>
    <div class="text-center">
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
            <h3 class="special-3 text-primary-1 mb-30" data-aos="fade-up" data-aos-duration="1500">VISION</h3>
            <p class="special-4 mb-80" data-aos="fade-up" data-aos-duration="1500">
              FINNX's vision is to "Elevate clients productivity and competitive edge”.
            </p>
            <h3 class="special-3 text-primary-1 mb-30" data-aos="fade-up" data-aos-duration="1500">MISSION</h3>
            <p class="special-4" data-aos="fade-up" data-aos-duration="1500">
              FINNX's mission is "to bring the best solutions to the customers through its innovative technology, software and services”.
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>