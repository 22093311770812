<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-finnx">
      <div class="container">
        <a class="navbar-brand" href="/consulting"><img src="@/assets/images/consult/logo-finnx-consulting-group.svg" alt="logo_finnx_consult"></a>

        <i class="fa-solid fa-bars icon-hamburger navbar-toggler collapsed" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>

        <div class="collapse navbar-collapse px-3" id="navbarCollapse">
          <div class="d-block d-lg-none">
            <div class="container">
              <div class="d-flex justify-content-between header-nav-collapse">
                <a class="navbar-brand" href="/"><img src="@/assets/images/consult/logo-finnx-consulting-group.svg" alt="logo_finnx"></a>
              
                <i class="fa-solid fa-xmark icon-close navbar-toggler collapsed" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
              </div>
            </div>
          </div>

          <ul class="navbar-nav text-center ms-auto">
            <li class="nav-item active">
              <a class="nav-link" href="#Consulting">Consulting</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#Services">Services</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#Professionals">Professionals</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#Customer">Customer’s Voice</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#GetInTouch">Get In Touch</a>
            </li>
            <li>
              <a class="nav-link" href="/">
                <button class="btn btn-border">FINNX Home</button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>


  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
* {
  font-family: 'Public Sans', sans-serif;
  font-weight: 500 !important;
}
.header-nav-collapse {
  padding: 30px 0px;
}
.icon-hamburger {
  color: #ffffff;
  border: none;
  font-size: 26px;
}
.icon-close {
  color: #ffffff;
  border: none;
  font-size: 32px;
  position: relative;
  top: 25px;
}
.nav-link {
  color: #ffffff;
  font-size: 18px;

  &:hover {
    color: #EF3E17;
  }
}
.active > .nav-link {
  color: #EF3E17 !important;
}
li.nav-item {
    margin-top: 8px;
}
.btn:hover {
  color: #EF3E17;
  border: 1px solid #EF3E17;
}
.btn-border {
  color: #EF3E17;
  border: 1px solid #EF3E17;
  padding: 10px 20px;
}

@media (max-width: 768px) {
  .btn-border {
    margin-top: 10px;
  }
  .header-nav-collapse {
    padding: 28px 0px;
  }
  .navbar-collapse {
    position: fixed;
    top: 54px;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    height: 100%;
    z-index: 10;
  }
  .navbar-collapse.collapsing {
    height: 100%;
    // width: 100%;
    left: -75%;
    transition: height 0s ease;
  }
  .navbar-collapse.show {
    height: 100%;
    width: 100%;
    left: 0;
    transition: left 300ms ease-in-out;

    background-color: #171717;
    top: 0px;
  }
  .navbar-toggler.collapsed ~ .navbar-collapse {
    transition: left 300ms ease-in;
  }
  .navbar-finnx {
    padding: 8px 16px;
  }
}
@media (max-width: 492px) {
  .navbar-finnx {
    padding: 8px 15px;
  }
}
</style>