<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-finnx">
      <div class="container">
        <a class="navbar-brand" href="/"><img src="../../assets/images/logo/logo.svg" alt="logo_finnx"></a>

        <i class="fa-solid fa-bars icon-hamburger navbar-toggler collapsed" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>

        <div class="collapse navbar-collapse px-3" id="navbarCollapse">
          <div class="d-block d-lg-none">
            <div class="container">
              <div class="d-flex justify-content-between header-nav-collapse">
                <a class="navbar-brand" href="/"><img src="../../assets/images/logo/logo.svg" alt="logo_finnx"></a>
              
                <i class="fa-solid fa-xmark icon-close navbar-toggler collapsed" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
              </div>
            </div>
          </div>

          <ul class="navbar-nav text-center ms-auto">
            <li class="nav-item active">
              <a class="nav-link" href="#home">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#about">About us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#visionandmission">Vision & Mission</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#ourservices">Services</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#news">News</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" target="_blank" href="https://bit.ly/hr_finnx">Careers</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#contact">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>


  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.header-nav-collapse {
  padding: 30px 0px;
}
.icon-hamburger {
  color: #ffffff;
  border: none;
  font-size: 26px;
}
.icon-close {
  color: #ffffff;
  border: none;
  font-size: 32px;
}
.nav-link {
  color: #ffffff;
  font-size: 18px;

  &:hover {
    color: #EF3E17;
  }
}
.active > .nav-link {
  color: #EF3E17 !important;
}

@media (max-width: 768px) {
  .header-nav-collapse {
    padding: 28px 0px;
  }
  .navbar-collapse {
    position: fixed;
    top: 54px;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    height: 100%;
    z-index: 10;
  }
  .navbar-collapse.collapsing {
    height: 100%;
    // width: 100%;
    left: -75%;
    transition: height 0s ease;
  }
  .navbar-collapse.show {
    height: 100%;
    width: 100%;
    left: 0;
    transition: left 300ms ease-in-out;

    background-color: #171717;
    top: 0px;
  }
  .navbar-toggler.collapsed ~ .navbar-collapse {
    transition: left 300ms ease-in;
  }
  .navbar-finnx {
    padding: 8px 16px;
  }
}
@media (max-width: 492px) {
  .navbar-finnx {
    padding: 8px 15px;
  }
}
</style>