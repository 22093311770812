<template>
  <div class="setup-section">
    <div class="container" id="GetInTouch">
      <div class="header-section">
        <h2 class="display-2 font-noto font-700 text-primary-1">Get In Touch</h2>
      </div>

      <div class="box-white">
        <div class="row">
          <div class="col-12 col-md-7 col-lg-6">
            <h3 class="display-3 mb-10 text-primary-1 font-public font-700">FINN X Co., LTD</h3>
            <p class="display-p mb-20 font-public">213/2 Asoke Towers (3rd Floor), Sukhumvit 21 Road, Khlong Toei Nuea, Wattana, Bangkok 10110</p>

            <div class="mb-20">
              <i class="fa-solid fa-phone fa-xl d-inline-block me-10"></i>
              <p class="mb-0 display-p font-public d-inline-block">095-324-2693 <span class="font-noto font-400">(เนตร)</span></p>
            </div>
            <div class="mb-20">
              <i class="fa-solid fa-envelope fa-xl d-inline-block me-10"></i>
              <p class="mb-0 display-p font-public d-inline-block">nadenapa.h@finnx.io</p>
            </div>
            <div class="mb-20">
              <i class="fa-brands fa-facebook fa-xl d-inline-block me-10"></i>
              <a href="https://www.facebook.com/FinnxConsulting/"><p class="mb-0 display-p font-public d-inline-block">FINNX Consulting Group</p></a>
            </div>

          </div>
          <div class="col-12 col-md-5 col-lg-4 offset-lg-2 my-auto">
            <a href="tel:+66953242693"><button class="btn btn-primary-1 w-100 font-noto display-p text-white">ติดต่อเรา</button></a>
          </div>
        </div>
      </div>
    </div>


    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/layouts/consult/Footer.vue'
  export default {
    components: {
      Footer,
    },
  }
</script>

<style lang="scss" scoped>
.setup-section {
  background-image: url('@/assets/images/consult/bg-consult.png');
  background-size: cover;
  padding-top: 170px;
  padding-bottom: 40px;
  margin-top: -130px;
}
.box-white {
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 10px;

  padding: 50px 70px;
}
a, .font-public {
  color: #171717;
}
a:hover {
  color: #171717;
}

@media (max-width: 768px) {
  .setup-section {
    padding-top: 200px;
    margin-top: -220px;
  }
  .box-white {
    padding: 40px 50px;
  }
}

@media (max-width: 492px) {
  .box-white {
    padding: 40px 20px;
  }
  .btn {
    margin-top: 30px;
  }
}
</style>