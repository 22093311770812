<template>
  <div>
    <div class="text-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
            <h3 class="special-3 text-primary-1 mb-30" data-aos="fade-up" data-aos-duration="1500">ABOUT US</h3>
            <p class="display-4 font-noto" data-aos="fade-up" data-aos-duration="1500">
              บริษัท ฟินน์ เอ็กซ์ จำกัด คือ บริษัทผู้เชี่ยวชาญด้านการวางระบบ และให้คำปรึกษาด้านการเงินการลงทุนสำหรับธุรกิจที่กำลังเติบโต และต้องการวางแผนสร้างกลยุทธ์ในการดำเนินธุรกิจ ด้วยประสบการณ์การทำงานจากผู้เชี่ยวชาญในฐานะ Refinn ที่ก่อตั้งตั้งแต่ปี 2015 ซึ่งได้รับความไว้วางใจจากธนาคารชั้นนำทุกแห่งมากกว่า 8 ปี และได้รับรางวัลระดับโลกมาแล้วมากมาย
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.font-noto {
  color: #FFF;
}
</style>