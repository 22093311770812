<template>
  <div>
    <Header id="Consulting"/>
    <UpgradeBusiness />
    <Services />
    <DataTransformation />
    <Professionals />
    <Customers />
    <Partners />
    <Blog />
    <GetInTouch />
  </div>
</template>

<script>
import Header from './Header.vue'
import UpgradeBusiness from './UpgradeBusiness.vue';
import Services from './Services.vue'
import DataTransformation from './DataTransformation.vue'
import Professionals from './Professionals.vue';
import Customers from './Customers.vue'
import Partners from './Partners.vue';
import Blog from './Blog.vue'
import GetInTouch from './GetInTouch.vue'
  export default {
    head () {
      return {
        title: this.title,
        meta: [
        //Google
          { name: 'description', content: this.description },
          { name: 'keywords', content: 'ปรึกษาธุรกิจ, ระดมทุน, pre ipo, ปรึกษาการตลาด, วางระบบบัญชี' },
        //Facebook
          { property: 'og:url',             content: this.path },
          { property: 'og:type',            content: 'website' },
          { property: 'og:title',           content: this.title },
          { property: 'og:description',     content: this.description },
          { property: 'og:image',           content: this.image },
          { property: 'fb:app_id',          content: '101962716020057' },
          { name: "facebook-domain-verification", content: "t80z73xvhg1y1f37v0spud165vu6ha" },
        //Twitter
          { name: "twitter:card",           content: "summary" },
          { name: "twitter:title",          content: this.title },
          { name: "twitter:description",    content: this.description },
          { name: "twitter:image",          content: this.image },
        ],
        // script: [
        //   {
        //     src: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
        //     'data-ad-client': 'ca-pub-8963878536478898',
        //     async: true
        //   }
        // ]
        // link: [{ rel: 'canonical', href: this.path }]
      }
    },
    computed: {
      title() {
        return 'ยกระดับธุรกิจของคุณ โดยผู้เชี่ยวชาญที่อยู่เบื้องหลังการเติบโตขององค์กรชั้นนำ | FINNX Consulting Group'
      },
      description() {
        return 'บริการให้คำปรึกษาธุรกิจ การบริหาร การตลาด การสร้างแบรนด์ การเงินและการบัญชี งานบริหารทรัพยากรบุคคล เพื่อยกระดับธุรกิจ เพิ่มประสิทธิภาพการทำงานขององค์กร'
      },
      // path () {
      //   return this.$config.baseURL + decodeURIComponent(this.$route.path)
      // },
      // image () {
      //   return this.$config.baseURL + '/images/og/thumbnail-og.png'
      // },
    },
    components: {
      Header,
      UpgradeBusiness,
      Services,
      DataTransformation,
      Professionals,
      Customers,
      Partners,
      Blog,
      GetInTouch
    },
  }
</script>

<style lang="scss">
@import url('@/assets/scss/custom/consulting.scss');
</style>