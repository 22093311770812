<template>
  <div>
    <div class="container">
      <div class="box-white">
        <div class="header-section mb-20">
          <h2 class="display-2 font-noto font-700 text-primary-1">องค์กรที่เคยร่วมงานกับบริษัทในเครือ</h2>
        </div>
        <!-- desktop -->
        <div class="d-none d-md-block">
          <img width="100%" src="@/assets/images/consult/partners-desktop.png" alt="">
        </div>
        <!-- mobile -->
        <div class="d-block d-md-none">
          <img width="100%" src="@/assets/images/consult/partners-mobile.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.box-white {
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 10px;
  padding: 50px 80px;
}

@media (max-width: 768px) {
  .box-white {
    padding: 30px 40px;
  }
}
@media (max-width: 492px) {
  .box-white {
    padding: 20px;
  }
}
</style>